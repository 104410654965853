/* General Body Styling */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Portal Container Styling */
.portal-ctn {
  display: flex;
  flex-direction: row-reverse;
  z-index: 1;
}

.watermark-bgr {
  position: relative;
  z-index: 0;
}

.watermark-bgr::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/watermarkbg.png");
  background-repeat: repeat;
  background-size: 200px 200px;
  opacity: 0.13;
  z-index: -1; 
}

.portal-ctn-blur {
  display: flex;
  flex-direction: row-reverse;
  z-index: 1;
  filter: blur(3px);
}

/* Navbar and Timer Styling */
.navbar .timer {
  padding: 0 10px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
}

/* Info Button Styling */
.info-btn {
  border: 2px solid #181261;
  background-color: transparent;
  color: #181261;
  width: 25px;
  height: 25px;
  font-weight: 500;
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  right: 15px;
  top: 10vh;
  cursor: pointer;
  z-index: 2;
}

.info-btn:hover {
  border: none;
  background-color: #181261;
  color: white;
}

/* Sidebar Styling */
.sidebar {
  margin-top: 10vh;
  width: 30vw;
  height: 80vh; /* Adjusted to take the full height */
  overflow-y: auto;
  background-color: rgb(80, 218, 62);
  border: 0 1px 0 0 solid rgb(184, 172, 172);
  border-top-left-radius: 10px;
  box-shadow: 2px 2px 5px 4px rgb(236, 230, 230);
}

/* Submit Button Styling */
.submit-button {
  border: none;
  border-radius: 6px;
  position: absolute;
  color: white;
  bottom: 1.6%;
  left: 9%;
  padding: 10px;
  background-color: blue;
  cursor: pointer;
}

/* Button Section Styling */
.btn-section {
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* Question Wrapper Styling */
.qn-wrapper {
  margin-top: 10vh;
  width: 75vw;

  height: 80vh; /* Adjusted for full viewport height */
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.qn-wrapper::-webkit-scrollbar {
  display: none;
}

/* Question Header Styling */
.qn-header {
  width: 75vw;
}

/* Card Styling */
.card {
  width: 85vw;
  height: 67vh;
  overflow-y: scroll;
  margin-bottom: 5vh;
}

.card::-webkit-scrollbar {
  display: none;
}

.qn-card {
  width: 85%;
  height: fit-content;
  border-radius: 9px;
}

.qn-card .qn-container {
  width: 80%;
  min-height: 20vh;
  max-height: fit-content;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.qn-card .qn-container .qn-img img {
  margin: 1px auto;
  width: 50em;
  height: auto;
}

.qn-card .qn-container .qn-content {
  margin-left: 2em;
}

/* Options Card Styling */
.options-card {
  width: 80%;
  margin: 0 auto;
  height: fit-content;
  border-radius: 9px;
  display: flex;
  flex-wrap: wrap;
}

.options-card h3 {
  width: 100%;
  margin-left: 10px;
}

.options-card button {
  width: 40%;
}

/* Navigation Button Card Styling */
.navigation-button-card {
  width: 100%;
  height: 10vh;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-button-card button {
  height: 30px;
  cursor: pointer;
  margin: 5px;
  background-color: blue;
  border: none;
  color: white;
  border-radius: 6px;
  padding: 0 10px;
}

/* Button States Styling */
.default-btn,
.active-btn,
.answered-btn,
.unanswered-viewed-btn {
  width: 3em;
  height: 3em;
  padding: 7px;
  border-radius: 3px;
  margin: 5px;
  cursor: pointer;
  border: none;
}

.default-btn {
  background-color: rgb(255, 255, 255);
  color: black;
}

.active-btn {
  background-color: blue;
  color: white;
}
.qn-here{
  margin-left: 2em;
}
.answered-btn {
  background-color: green;
  color: white;
}

.unanswered-viewed-btn {
  background-color: red;
  color: white;
}

/* Option Button States Styling */
.default-option {
  background-color: #181261;
  border: 1px solid #371c68;
  padding: 10px;
  margin: 5px;
  color: white;
  position: relative;
  border-radius: 10px;
}
.question-line{
  margin-left: 5em;
  width: 83%;
  margin-bottom: 1em;
}

.default-option:hover {
  background-color: #1b2c8d;
  cursor: pointer;
}

.active-option {
  background-color: #048319;
  color: white;
  border: 1px solid #046b08;
  padding: 10px 10px 10px 35px;
  margin: 5px;
  position: relative;
  border-radius: 10px;
}

/* Info Card Styling */
.info-card {
  overflow-y: auto;
  margin-top: 10vh;
  height: 89vh;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 0px 20px 40px 5px;
  z-index: 4;
  font-size: 12px;
  overflow-x: hidden;
  width: 30vw;
  background-color: rgb(238, 237, 237);
  border-top-left-radius: 10px;
  box-shadow: 2px 1px 9px 3px rgb(116, 114, 114);
}

.info-card .container::-webkit-scrollbar {
  display: none;
}

.info-card .container h1 {
  margin-bottom: 2px;
  font-size: 20px;
  color: #000000;
}

.info-card .container .close-btn {
  border: none;
  position: absolute;
  top: 2px;
  right: 10px;
  background-color: transparent;
  color: red;
  width: 25px;
  height: 25px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}

.info-card .instruction {
  margin: 2px 0;
}

.info-card .instruction ul {
  list-style-type: square;
  font-size: 0.7rem;
  margin: 0;
  padding: 0 20px;
}

.info-card .instruction ul li {
  margin: 10px 0;
  font-size: 12px;
  line-height: 1.5;
  color: #010101;
}

.info-card .writeexam {
  margin-top: 20px;
  text-align: left;
}

.info-card .writeexam h2 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #020202;
}

.info-card .writeexam p {
  font-size: 14px;
  color: #010101;
  margin-bottom: 10px;
}

.info-card .writeexam ul {
  list-style-type: disc;
  margin: 0;
  padding: 0 20px;
}

.info-card .writeexam ul li {
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.5;
  color: #010101;
}

.info-card .navigate h2 {
  font-size: 18px;
}

.info-card .navigate ul li {
  font-size: 14px;
}

.info-card .sections-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  text-align: left;
}

.info-card .sections-table th,
.sections-table td {
  padding: 12px;
  border: 1px solid #ddd;
}

.info-card .sections-table th {
  background-color: #f2f2f2;
}

.info-card .sections-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.info-card .answer-status {
  margin-top: 20px;
}

.info-card .answer-status p {
  display: flex;
  align-items: center;
}

.info-card .answer-status p span {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
}

.default {
  background-color: white;
  border: 1px solid #cccccc;
}

.answered {
  background-color: green;
}

.unanswered {
  background-color: red;
}

.unanswered-viewed {
  background-color: blue;
  border: 1px solid blue;
}

.submit-button:hover {
  background-color: rgb(2, 12, 97);
}
