.slider-container {
    width: 100%;
    margin: auto;
  }
  
  .image-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
    overflow: hidden; 
  }
  
  .slider-image {
    width: 100%;
    height: 80%;
    object-fit: cover; 
  }

  @media screen and (max-width: 960px)  {
    .slider-image {
      width: 100%;
      height: 70%;
      object-fit: cover; 
    }
  }
  