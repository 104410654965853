* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	text-decoration: none;
	background: transparent;
}
body {
	background: #fff;
  font-family: Arial, Helvetica, sans-serif;
}
::-webkit-scrollbar {
    display: none;
}
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pad {
  padding: 0 6rem;
}

/* Navbar */
.navbar {
	display: flex;
	justify-content: space-between;
  align-items: center;
	backdrop-filter: blur(10px);
  background-color: white;
	padding: 0.5em 6em;
  font-family: "Righteous", sans-serif;
}
.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.logoTitle {
  display: flex;
  color: black;
  font-size: 28px;
  letter-spacing: 5px;
}
.logoName {
  color: black;
  margin-left: 10px;
  margin-top: 24px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.tags {
  color: #fff;
	display: table-cell; 
	position: relative;
  padding: 20px;
}
.navLink {
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	transition: 0.3s;
}
.navLink:after {
	border-radius: 5px;
	background: none repeat scroll 0 0 transparent;
	bottom: 10px;
	content: "";
	height: 2px;
	left: 50%;
	position: absolute;
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	width: 0;
}
.navLink:hover {
	color: #6dcff4;
	cursor: pointer;
}
.navLink:hover:after { 
	width: 100%;
	left: 0;
	background-color:#6dcff4;
}

/* Hero */
.hero {
  height: 90vh;
  justify-content: space-evenly;
}
.heroContent {
  width: 500px;
  font-family: "Kode Mono", monospace;
}
.heroContent h1 {
  font-size: 40px;
  cursor: pointer;
  letter-spacing: 2px;
}
.char {
  display: inline-block;
  transition: transform 0.3s, margin 0.3s;
}

.char:hover {
  transform: scale(2);
}

.char:hover ~ .char {
  transform: scale(1.4);
  margin-left: 10px;
}

.char:hover ~ .char ~ .char {
  transform: scale(1.2);
  margin-left: 5px;
}

/* For the previous characters */
.char:hover ~ .char + .char {
  transform: scale(1.4);
  margin-right: 10px;
}

.char:hover ~ .char + .char + .char {
  transform: scale(1.2);
  margin-right: 5px;
}
.heroContent p {
  padding: 30px 0;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.getStarted {
  padding: 15px 30px;
  border: 2px solid;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 0 10px #ddd;
  background-color: #2a1965;
  color: #fff;
	position: relative;
	overflow: hidden;
	transition: 0.5s;
}
/* .getStarted:before {
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  background-color: #261648;
  box-shadow: 0 0 30px #261648;
	transform: translateX(-115%);
	transition: 0.5s;
	position: absolute;
  z-index: -1;
  border-radius: 30px;
} */
.getStarted:hover {
  cursor: pointer;
  border: 2px solid #261648;
  color: #2a1965;
  background-color: #fff;
}
.getStarted:hover::before {
	transform: translateX(0%);
}
.heroImg {
  width: 400px;
}
.category {
  flex-direction: column;
}
.category h2 {
  position: relative;
  margin-bottom: 30px;
}
.category h2::after {
	content: "";
  background: #261648;
	position: absolute;
	bottom: -10px;
  left: 0;
  right: 0;
	height: 2px;
  margin: auto;
	width: 50%;
}

/* About */
.aboutUs {
  margin-bottom: 60px;
}
.abtDesp {
  padding: 0 0 30px;
  line-height: 25px;
  text-align: center;
  width: 80%;
  opacity: 0.75;
}

/* Services */
.serCard {
  position: relative;
  transition: margin-right 0.5s;
}
.serviceCont {
  display: flex;
  font-size: 20px;
}
.service {
  box-shadow: 0 0 10px #bbb;
  height: 260px;
  margin: 0 0 0 10px;
  border-radius: 10px;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.serName {
  font-size: 16px;
  writing-mode: vertical-lr;
  letter-spacing: 1px;
  transform: rotate(180deg);
}
.serNum {
  font-weight: bold;
}
.scrollCardHover {
  position: absolute;
  display: none;
  flex-direction: column;
  left: -115px;
  height: 100%;
  transition: left 0.5s ease-in-out;
}
.serSccrollCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #261648;
  color: #fff;
  padding: 20px;
  width: 180px;
  height: 200px;
  border-radius: 0 20px 20px 0;
}
.service:hover ~ .scrollCardHover, .scrollCardHover:hover {
  display: flex;
  left: 78px;
}
.serCard:hover {
  margin-right: 200px;
}
.serIcon {
  width: 60px;
  border-radius: 30px;
  box-shadow: 0 0 5px #261648;
  position: absolute;
  top: -30px;
  right: -20px;
}
.serTitle {
  font-weight: bold;
  font-size: 16px;
}
.serDesp {
  font-size: 14px;
  opacity: 0.5;
  margin: 10px 0;
}
.serBtn {
  padding: 10px 20px;
  border-radius: 20px;
  border: solid 2px;
  background-color: #fff;
  color: #261648;
  transition: background-color 0.3s;
}
.serBtn:hover {
  cursor: pointer;
  border: solid 2px #fff;
  background-color: #261648;
  color: #fff;
}

/* Why Choose VTS */
.chooseCont {
  margin: 60px 0;
  padding: 60px 6rem;
  background-color: #261648;
  color: #fff;
}
.chooseCont > p {
  font-size: 25px;
  padding-bottom: 60px;
  text-align: center;
}
.reasonCont {
  display: flex;
  justify-content: space-evenly;
}
.reasons {
  width: 250px;
  background-color: #fff;
  border-radius: 20px;
  color: #261648;
  padding: 30px;
}
.chooseTitle {
  font-weight: bold;
  padding: 20px 0;
  font-size: 20px;
}
.chooseDesp {
  opacity: 0.5;
}

/* Footer */
.footer {
  background-color: black;
  padding: 30px 0;
  color: #fff;
  text-align: center;
  line-height: 25px;
}

/* Contact */
.contact {
  margin-bottom: 80px;
}
.contactCont {
  position: relative;
  box-shadow: 0 0 10px #bbb;
  border-radius: 20px;
  align-items: baseline;
  color: #fff;
}
.contactDetails {
  background-color: #261648;
  padding: 20px;
  border-radius: 20px 0 0 20px;
}
.contactCard {
  background-color: #2a1965;
  border-radius: 15px;
  color: #fff;
  display: flex;
  width: 320px;
  padding: 15px;
  margin-top: 20px;
}
.contactCard img {
  height: 40px;
  background-color: #261648;
  padding: 8px;
  border-radius: 20px;
  margin-right: 20px;
}
.contactDetailCard h3 {
  margin: 10px 0;
}
.contactDetailCard p {
  line-height: 25px;
}

/* Registration */
.regForm {
  position: relative;
  scale: 1;
  background-color: #fff;
  padding: 40px 40px 10px;
  box-shadow: 0 0 10px #bbb;
  border-radius: 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cross {
  position: absolute;
  height: 25px;
  width: 25px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  background-color: #ddd;
  color: #555;
  transition: all 0.3s;
  top: 15px;
  right: 15px;
}
.cross:hover {
  cursor: pointer;
  background-color: red;
  color: #fff;
}
.regTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #261648;
}
.ant-form-item {
  width: 100%; 
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0 10px;
}
.ant-input-prefix {
  opacity: 0.4;
}
.ant-input::placeholder {
  color: #606060;
}
.ant-select-arrow {
  font-size: 16px;
}
.ant-select-selector {
  padding: 0 12px;
}
.ant-form-item-explain-error {
  margin-bottom: 10px;
  width: fit-content;
}
.ant-form-item-explain {
  display: flex;
  justify-content: space-between;
}
.ant-input-outlined {
  border: 1px solid #261648;
  border-radius: 10px;
}
.ant-select-selector {
  border: 1px solid #261648 !important;
  border-radius: 10px;
}
.ant-select-selection-placeholder {
  color: #606060 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contactinpt {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.contactinpt input, .contactinpt textarea {
  border: 1px solid #ddd;
  padding: 15px;
}
.name_email {
  display: flex;
  justify-content: space-between;
  padding: 0 40px 25px;
  width: 100%;
}
.name_email input{
  width: 240px;
  outline: none;
}
.sub_msg {
  width: 100%;
  padding: 0 40px;
}
.contactSubject, .contactMsg {
  padding: 10px 20px;
  margin-bottom: 25px;
  width: 100%;
  outline: none;
}
.contactBtn {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #3914aa;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.5s;
}
.contactBtn:hover {
  cursor: pointer;
  background-color: #261648;
}
.regFormCont {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #bbbbbb6a;
  height: 100vh;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* login */
.loginPageCont {
  display: flex;
  height: 100vh;
}
.loginImgCont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
    rgba(199, 27, 27, 0.58),
    rgba(212, 212, 212, 0.671)
), url("../public/images/bgimage.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 450px;
  /* background-color: rgb(2, 0, 3); */
  color: #fff;
}
.sidebarTop {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  padding: 30px 0 0 30px;
}
.sidebarTop img {
  height: 50px;
}
.sidebarMiddle {
  padding: 0 30px;
  text-align: center;
}
.sidebarMiddle p {
  line-height: 25px;
  opacity: 0.6;
  margin-top: 15px;
}
.sidebarBottom {
  text-align: center;
  font-size: 12px;
  padding: 0 20px 30px;
}
.loginCont {
  width: calc(100% - 350px);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.netSpeed p {
  text-align: center;
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
}
.circleCont {
  position: relative;
  background-color: #6dcff4;
  margin-bottom: 200px;
  margin-left: 20px;
}
.outerCircle {
  position: absolute;
  top: 0;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  box-shadow: 0 0 5px #bbb;
  /* background: conic-gradient(#261648 30deg, #fff 0deg); */
}
.innerCircle {
  position: absolute;
  top: 7.5px;
  left: 7.5px;
  box-shadow: 0 0 3px #bbb;
  background-color: #fff;
  border-radius: 50%;
  width: 135px;
  height: 135px;
}
.speedNum {
  position: absolute;
  text-align: center;
  left: 43px;
  top: 43px;
  font-size: 20px;
}
.goBtn {
  position: absolute;
  border: none;
  padding: 20px;
  border-radius: 50%;
  background-color: #3914aa;
  color: #fff;
  font-size: 20px;
  transition: background-color 0.5s;
}
.goBtn:hover {
  cursor: pointer;
  background-color: #2a1965;
}
.retryBtn {
  border-radius: 50%;
  border: none;
}
.retryBtn:hover {
  cursor: pointer;
}
.speedNum p {
  position: absolute;
  left: 6px;
  top: 6px;
  font-size: 20px;
}
.netStatusImg {
  width: 62px;
}
.loginCard {
  display: flex;
  width: 350px;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 0 10px #ddd;
  border-radius: 20px;
  padding: 30px;
}
.loginTitle {
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}
.loginCond {
  margin: 10px 0 25px;
  opacity: 0.6;
  line-height: 24px;
  font-size: 14px;
}
.logDetails {
  margin-top: 20px;
}
.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.details label {
  font-weight: 600;
}
.loginCard input[type='submit'] {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  background-color: #2a1965;
  color: #fff;
  font-weight: 500;
}
.loginCard input[type='submit']:hover {
  cursor: pointer;
}
.disable {
  cursor: not-allowed;
  background-color: #555 !important;
  opacity: 0.8;
}

/* instructions */
.instruction {
  margin: 20px 0;
  padding: 30px;
  overflow-y: auto;
  overflow:hidden;
}
.instruction ul {
  list-style-type:number;
  margin: 0;
  padding: 0 30px;
}
.instruction ul li {
  margin: 10px;
  font-size: 18px;
  line-height: 1.5;
  color: #010101;
}
.instruction h2 li{
  color:red;
}
.instruction h2 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #020202;
}
.instruction u h2{
  padding: 0 20px;
}
.instruction h4{
  color:red;
  padding: 0 20px;
}
.writeexam {
  margin-top: 20px;
  text-align: left;
}
.writeexam h2 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #020202;
  padding: 0 20px;  
}
.writeexam p {
  font-size: 18px;
  color: #010101;
  margin-bottom: 10px;
  padding: 0 20px;
}
.writeexam ul {
  list-style-type:disc;
  margin: 0;
  padding: 0 35px;
}
.writeexam ul li {
  margin: 10px 0;
  font-size: 18px;
  line-height: 1.5;
  color: #010101;
}
.sections-table h2 li{
  color:red;
}
.navigate {
  display: flex;
  flex-direction: column;
}
.navigate h2{
  font-size: 20px;
}
.navigate p{
  padding: 0 20px;
}
.navigate ul{
  padding: 0 35px;
}
.sections-table {
  width: 80%;
  margin-top: 20px;
  border-collapse: collapse;
  text-align: left;
  margin-left:1.5%;
}
.sections-table th, .sections-table td {
  padding: 17px;
  border: 1px solid #ddd;
}
.sections-table th {
  background-color: #f2f2f2;
}
.sections-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.total-marks-row td {
  font-weight: bold;
}
.answer-status {
  margin-top: 20px;
}
.answer-status p {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #010101;
  padding: 0 20px;
}
.answer-status .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.answer-status .green {
  background-color: green;
}
.answer-status .blue {
  background-color: blue;
}
.examStartBtn {
  background-color: #0f0;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  margin-top: 20px;
  align-self: center;
  font-size: 18px;
  transition: background-color 0.3s;
}
.inactive {
  cursor: not-allowed !important;
  background-color: #0f0 !important;
}
.examStartBtn:hover {
  cursor: pointer;
  background-color: #0d0;
}
.testsubmitted {
  margin: 50px 0 30px;
  color: green;
  text-align: center;
}
.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-table {
  width: 50vw;
  border-collapse: collapse;
  text-align: center;
}
.section-table th, .section-table td {
  padding: 17px;
  border: 1px solid #ddd;
}

.section-table th {
  background-color: #f2f2f2;
}

.section-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.navigation h3 {
  margin-top: 50px;
  color: #261648;
}

.textarea-container {
  margin-top: 20px;
}

.feedback-textarea {
  padding: 10px;
  width: 300px;
  font-size: 14px;
  border: 2px solid #261648;
  border-radius: 4px;
  resize: vertical;
  outline: none;
}

.rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.star-rating {
  font-size: 24px;
  color: #cbc7c7;
  border-color: #261648;
  margin-top: 10px;
}

.star-rating i {
  cursor: pointer;
  transition: color 0.3s;
}

.star-rating i.active {
  color: #ffd700;
}
.star-rating i:hover {
  color: #ffd700;
}
.submit {
  margin-top: 20px;
  padding: 10px 20px;
}

.submit button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #261648;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit button:disabled {
  background-color: #ccc; 
  cursor: not-allowed;
}

.mobile-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  text-align: center;
  background-color: white;
  color: red;
  padding: 20px;
  box-sizing: border-box;
}
.view-report-btn{
  padding: 7px;
  border: none;
  border-radius: 8px;
  margin: 10px auto;
  background-color: #261648;
  color: white;
}

@page {
  size: A4;
  margin: 20px;
}
.card-results{
  
  display: flex;
  justify-content: space-evenly;
  width: 70vw;
  margin: 0 auto;
  border-bottom: none;
}
.res-card-main{

  border: black 2px solid;
  border-bottom: none;
}
.res-nav{
  display: flex;
  justify-content: space-around;
  width: 70.2vw;
  margin: 0 auto;
  border: black 2px solid;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: none;
  padding-left: 3px;
  padding-right: 3px;
  align-items: center;

}

.card-results-pes-detls h2{
  padding: 10px 0px;
}
.card-results-pes-detls p{
  padding: 6px 0;
}

.res-nav img{
  width: 100px;
  height: 100px;
}

.res-logo {
  display: flex;

}
.report-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-btn {
  background-color: #007bff;
}

.report-btn:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.watermark{
  width: 45%;
}

.blur-background {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.watermark-vts{
  display: flex;
  align-items: center;
  position: absolute;
  opacity: 30%;
  top: 2vh;
  left: 48vw;
}
.watermark-vts span{
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 800;
}

.instr-watermark{
  width: 400%;
  position: absolute;
  top: 10vh;
  left: 23%;
  opacity: 30%;
}

.centered-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* adds 10px space between the buttons */
}

.centered-buttons button {
  margin: 10px;
}
.instruction-page{
  margin: 0;
padding: 0;
  font-size: 14px;
  width: 100%;
  height: fit-content;
  overflow-x: hidden;
}