@media screen and (max-width: 500px) {
 
  body {
    height: 100vh;
    margin: 0;padding: 0;
    overflow: hidden;
  }
  
  .logoTitle{
    flex-direction: column;
    position: relative;
    left: -2vw;
    top: 1vh;
    width: 100vw;
  }
  .logoName{
    display: block;
    position:absolute;
    top: 3vh;
    right: 30px;
    
  }
 
  .loginCard {
    align-self: center;
    padding: 0px;
    position: absolute;
    top: 35vh;
    margin-left: 30px;
    left: 0;
    display: flex;
    width: 300px;
    flex-direction: column;
    text-align: center;
    box-shadow: 0 0 10px #ddd;
    border-radius: 20px;
    padding: 15px;
  }
  .loginTitle{
    text-align: center;
    font-size: 15px;
    padding: 4px;
    width: 90%;
  }
  .loginCard h5{
    margin-top: 20px;
    font-size: 11px;
  }
  .loginImgCont {
    margin: 0;
    padding: 0;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  .watermark-vts{
    display: none;
  }
  .sidebarTop{
    flex-direction: column;
    font-size: 18px;
  }
  .sidebarMiddle {
    font-size: 14px;
    position: absolute;
    top: 20vh;
  }

  .logDetails{
    font-size: 12px;
    text-align: left;
  }
.instruction-page{
  font-size: 12px;
  width: 380px;
  /* background-color: red; */
  overflow-x: hidden;

}
.portal-ctn{
  margin: 0;padding: 0;
  width: 100vw;
  flex-direction: column;
}
.qn-wrapper{
  width: 100vw;
  padding: 5px;
}
.default-btn,
.active-btn,
.answered-btn,
.unanswered-viewed-btn{
  width: 50px;
  height: 50px;
  padding: 10px 20px;
}
.info-btn{
  display: none;
}
.qn-wrapper{
  padding: 12vh 0;margin: 0;
}
.qn-wrapper h3{
  /* margin-left: -3em; */
}
.btn-section{
  position: absolute;
  top: 0;
  left: 0;
 background-color: black;
 display: flex;
 flex-wrap: nowrap;
 width: 100vw;
 height: 8vh;
 z-index: 11000;
 flex-direction: row;
 overflow-x: scroll;

}
.btn-section {
  height: fit-content;
  display: flex;
  justify-content: flex-start; /* Align items to the start of the row */
  flex-wrap: nowrap; /* Prevent wrapping to a new line */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical overflow if needed */
  white-space: nowrap; /* Ensure items don't wrap to the next line */
}

/* Optional: Add styles for the scrollbar (for WebKit browsers) */
.btn-section::-webkit-scrollbar {
  height: 8px; 
}

.btn-section::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Scrollbar thumb border radius */
}

.btn-section::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Scrollbar thumb color on hover */
}

.qn-card {
  overflow: visible;
  width: 100vw;
}
.qn-container{
  padding: 0;
  width: 100vw;
}
.card{
  width: 100vw;
}

.qn-card .qn-container .qn-content{
  margin-left: 0em;
}
.question-line{
  margin: 1em;
}
.default-option{
  font-size: 12px; 
}
.navigation-button-card {
  position: absolute;
  bottom: 10vh;
}
.sidebar{
  width: 0;
  height: 0
}
.sidebar h3{
  
  position: absolute;
  bottom: 0vh;
  right: 5vw;
}
.res-d-dlex{
  overflow-x: hidden;
  font-size: 12px;
  width: 100vw;
  padding: 0;
  flex-direction: column-reverse;
}
.res-d-dlex h1{
  width: 100%;
  padding: 20px;
  margin-left: 30vw;
  font-size: 18px;
}
.heroContent p{
  margin-left: 30px;
  height: 150vh;
  padding:20px;
  width: 100vw;
  padding-left: 15vw;
  font-size: 14px;

}
.heroContent button{
  position: absolute;
  left: 10vw;
  bottom: 0;
}
.heroImg {
  width: 200px;
  height: 200px;
}

}
