.admin-page {
    padding: 20px;
  }
  
  .assessments-section {
    margin-top: 20px;
  }
  
  .ant-table {
    margin-top: 20px;
  }
  
  .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
    color: white;
  }
  
  .ant-btn-primary:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  
  .ant-modal {
    top: 20px;
  }
  
  .ant-form-item {
    margin-bottom: 16px;
  }
  