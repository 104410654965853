@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Montserrat", sans-serif;
}

.stats_box {
  border: 2px solid #ffffff;
  background-color: rgba(0, 0, 0, 0.082);
  backdrop-filter: blur(30px);
  z-index: 20;
  border-radius: 32px;
}

.search_drop_shadow {
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.14));
}

.more_news_gradient {
  background: linear-gradient(
    0deg,
    #ffffff 58.57%,
    rgba(255, 255, 255, 0) 95.77%
  );
  margin-top: -5rem;
}

.news_card_shadow {
  box-shadow: 0px 3.48006px 12.1802px rgba(0, 0, 0, 0.05);
}

/* Funds Tabs styles */
.react-tabs__tab--selected {
  border-bottom: 2px solid green !important;
  background: transparent !important;
  font-weight: bold;
  color: green !important;
  transition: all 0.3s ease-in-out;
  outline: none !important ;
}
